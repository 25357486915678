import Alpine from 'alpinejs';
import dayjs from "dayjs";
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isBetween from 'dayjs/plugin/isBetween';
import Dismissible from './dismissible';
import OnScroll from './onscroll';
import Collapsable from './collapsable';
import Modal from '@vrembem/modal';
import Splide from '@splidejs/splide';
import Masonry from 'masonry-layout';

/**
 * Local modules
 */

Dismissible();
OnScroll();
Collapsable();

/**
 * Alpine JS (for Yext)
 */
window.Alpine = Alpine;
Alpine.start();

/**
 * Day JS (for Yext)
 */
dayjs.extend(customParseFormat);
dayjs.extend(isBetween);
window.dayjs = dayjs;

/**
 * Modals
 */

const modal = new Modal({
  autoInit: true,
  selectorInert: '[role="main"]',
  selectorOverflow: 'body, [role="main"]',
  moveModals: {
    type: 'after',
    ref: '[role="main"]'
  }
});

// Get all shop links
const shopLinks = document.querySelectorAll('.shop-link');

// Apply event listeners to all shop links
shopLinks.forEach((link) => {
  link.addEventListener('click', (event) => {
    // Check if link has been clicked
    if (!localStorage.getItem('shop-link-clicked')) {
      // Prevent default event behavior
      event.preventDefault();

      // Set the local storage value
      localStorage.setItem('shop-link-clicked', 'true');

      // Open the shop modal
      modal.open('shop-modal');
    }
    document.getElementById("btn-modal-shop").setAttribute("href", link.dataset.link);
    document.getElementById("btn-modal-shop").textContent = link.dataset.text;
  });
});

/**
 * Masonry
 */

// Get all masonry grid items
const masonryItems = document.querySelectorAll('.grid_masonry');

// Loop through them if at least one exists
if (masonryItems.length) {
  masonryItems.forEach((item) => {

    // Create an instance of each masonry grid
    let masonry = new Masonry(item, {
      itemSelector: '.grid__item'
    });

    // Trigger the layout once all images have loaded
    window.onload = function () {
      masonry.layout();
    }
  });
}

/**
 * Mobile menu children builder
 */

var modal__menu = document.querySelectorAll('.modal .menu');

for (var i = 0; i < modal__menu.length; i++) {

  var children = modal__menu[i].querySelectorAll('ul');

  for (var c = 0; c < children.length; c++) {
    children[c].parentElement.classList.add('has-children');
    var toggle = document.createElement('span');
    toggle.classList.add('children-toggle');
    children[c].parentElement.insertBefore(toggle, children[c]);
  }
}

/**
 * Toggle mobile menu children
 */

document.addEventListener('click', function (event) {
  var trigger = event.target.closest('.children-toggle');

  if (trigger) {
    trigger.nextSibling.classList.toggle('display-block');
  }
}, false);

/**
 * General toggle (js-toggle)
 */

document.addEventListener('click', function (event) {
  var trigger = event.target.closest('.js-toggle');
  if (trigger) {
    var target = document.getElementById(trigger.dataset.target);
    if (target) {
      trigger.classList.toggle('is-active');
      target.classList.toggle(trigger.dataset.toggle);
    }
  }
}, false);

/**
 * Slideshow
 */

var slideshow = document.querySelector('.slideshow');
if (slideshow) {
  var current = 0;
  var slides = slideshow.querySelectorAll('img');
  slides[0].classList.add('is-active');
  setInterval(function () {
    for (var i = 0; i < slides.length; i++) {
      slides[i].classList.remove('is-active');
    }
    current = (current != slides.length - 1) ? current + 1 : 0;
    slides[current].classList.add('is-active');
  }, 6000);
}

/**
 * Splide
 */

const heroCarousel = document.querySelector('#hero-carousel');
if (heroCarousel) {
  new Splide(heroCarousel, {
    type: 'loop',
    perPage: 1,
    autoplay: true,
    arrows: false,
    pagination: false,
    interval: splideInterval,
  }).mount();
}

const imageCarousel = document.querySelector('#image-carousel');
if (imageCarousel) {
  new Splide('#image-carousel').mount();
}