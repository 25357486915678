import u from './utility'

export default function () {

  'use strict'

  let api = {}
  let settings
  let defaults = {
    classTarget: 'onscroll',
    classActive: 'is-active'
  }

  let run = () => {
    const targets = document.querySelectorAll('.' + settings.classTarget)
    for (let target of targets) {
      const trigger = document.querySelector(target.dataset.trigger)
      if (trigger) {
        if (window.scrollY > (trigger.offsetTop + trigger.offsetHeight)) {
          target.classList.add(settings.classActive)
        } else {
          target.classList.remove(settings.classActive)
        }
      }
    }
  }

  api.init = (options) => {
    api.destroy()
    settings = u.extend(defaults, options || {})
    run()
    document.addEventListener('scroll', run, false)
    document.addEventListener('touchmove', run, false)
  }

  api.destroy = () => {
    settings = null
    document.removeEventListener('scroll', run, false)
    document.removeEventListener('touchmove', run, false)
  }

  api.init()

  return api
}
