import u from './utility'

export default function () {

  'use strict'

  let api = {}
  let settings
  let defaults = {
    stateOpen: 'is-open'
  }

  let run = (event) => {
    const trigger = event.target.closest("[data-collapse]")
    if (trigger) {
      const target = trigger.closest("[data-collapsable]")
      if (target) {
        u.toggleClass(target, settings.stateOpen)
      }
    }
  }

  api.init = (options) => {
    settings = u.extend(defaults, options || {})
    document.addEventListener('click', run, false)
  }

  api.destroy = () => {
    settings = null
    document.removeEventListener('click', run, false)
  }

  api.init()

  return api
}
