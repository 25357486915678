import u from "./utility";

export default function () {
    "use strict";

    let api = {};
    let settings;
    let defaults = {
        trigger: "[data-dismiss]",
        target: "[data-dismissible]",
        classToggle: "dismiss",
        dismissableCookie: "data-dismissed",
    };

    let run = (event) => {
        let trigger = event.target.closest(settings.trigger);
        if (trigger) {
            let target = trigger.closest(settings.target);
            if (target) {
                u.toggleClass(target, settings.classToggle);
                if (target.hasAttribute(settings.dismissableCookie)) {
                    let dismissableAttr = target
                        .getAttribute(settings.dismissableCookie)
                        .toString()
                        .split("|", 2);
                    api.setCookie(dismissableAttr[0], dismissableAttr[1]);
                }
            }
            event.preventDefault();
        }
    };

    api.setCookie = (name, value, days = 30) => {
        document.cookie = `${name}=${value};path=/;max-age=${60 * 60 * 24 * days}`;
    };

    api.destroy = () => {
        settings = null;
        document.removeEventListener("click", run, false);
    };

    api.init = (options) => {
        api.destroy();
        settings = u.extend(defaults, options || {});
        document.addEventListener("click", run, false);
    };

    api.init();

    return api;
}
